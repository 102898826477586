import React, { useState } from "react";
import {
  Table,
  Card,
  Typography,
  Row,
  Col,
  Input,
  Button,
  Popconfirm,
  message,
  Tooltip,
  Tag,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Container, RowInfo } from "./elements";
import { useQuery, useMutation } from "@apollo/client";
import { GET_USERS_SALESPERSON, DELETE_USER } from "./requests";
import NewSalesperson from "./components/new-salesperson/index";
import { removeToken } from "utils/auth/index";

const { Title, Text } = Typography;

const Salesperson = () => {
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [showNewSalesperson, setShowNewSalesperson] = useState(false);
  const [editingSalesperson, setEditingSalesperson] = useState(null);

  const {
    data: usersData,
    refetch,
    loading,
  } = useQuery(GET_USERS_SALESPERSON, {
    variables: {
      filters: {
        name: searchName,
        email: searchEmail,
        userType: "salesperson",
      },
    },
  });

  const [deleteUser] = useMutation(DELETE_USER);

  const allSalespersons = usersData?.users?.results || [];

  const handleDelete = async (id) => {
    try {
      await deleteUser({ variables: { id } });

      message.success("Vendedor eliminado correctamente.");
      refetch(); // Recargar la lista de empleados
    } catch (error) {
      message.error("Error al eliminar el empleado.");
    }
  };

  const handleEdit = (user) => {
    setEditingSalesperson(user);
    setShowNewSalesperson(true);
  };

  const handleLogout = async (userId) => {
    try {
      await removeToken(userId);
      message.success("Sesión cerrada exitosamente");
      refetch();
    } catch (error) {
      message.error("Error al cerrar sesión");
    }
  };
  const columns = [
    {
      align: "left",
      width: 120,
      key: "img",
      render: (row) =>
        row.img ? (
          <img
            src={row.img}
            alt="Vendedor"
            style={{ width: 60, height: 60, borderRadius: 50 }}
          />
        ) : null,
    },
    {
      title: "Vendedor",
      align: "left",
      key: "user",
      render: (user) => {
        const fullName = user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.firstName;

        return (
          <RowInfo>
            <Title level={5}>{fullName}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Correo",
      align: "left",
      key: "email",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{row.email}</Title>
        </RowInfo>
      ),
    },
    {
      title: "Teléfono",
      align: "left",
      key: "phone",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{row.phone ?? row.mobile}</Title>
        </RowInfo>
      ),
    },
    {
      title: "ID Franquicia",
      align: "left",
      key: "franchiseId",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{row.franchiseId}</Title>
        </RowInfo>
      ),
    },
    {
      title: "Estado de Sesión",
      align: "left",
      key: "session",
      render: (row) =>
        row.activeToken ? (
          <Tag color="green">Activa</Tag>
        ) : (
          <Tag color="red">Inactiva</Tag>
        ),
    },
    {
      title: "Acciones",
      align: "center",
      key: "actions",
      render: (row) => (
        <Row>
          {row.activeToken && (
            <Tooltip title="Cerrar sesión">
              <Button
                shape="circle"
                type="primary"
                icon={<LogoutOutlined />}
                onClick={() => handleLogout(row.id)}
                style={{ marginRight: 8 }}
              />
            </Tooltip>
          )}
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(row)}
            style={{ marginRight: 8 }}
          />
          <Popconfirm
            title="¿Seguro que deseas eliminar este uuario de venta?"
            onConfirm={() => handleDelete(row.id)}
            okText="Sí"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Row>
      ),
    },
  ];

  return (
    <Container>
      <Card style={{ borderRadius: 5 }}>
        <Text style={{ fontSize: 20, fontWeight: "800" }}>Vendedores</Text>
        <Row justify="space-between">
          <Col span={16}>
            <Row gutter={[16, 16]}>
              <Col>
                <Text strong>Filtrar por Nombre</Text>
                <Input
                  placeholder="Buscar por Nombre"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  allowClear
                />
              </Col>
              <Col>
                <Text strong>Filtrar por Correo</Text>
                <Input
                  placeholder="Buscar por Correo"
                  value={searchEmail}
                  onChange={(e) => setSearchEmail(e.target.value)}
                  allowClear
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              size="large"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setEditingSalesperson(null);
                setShowNewSalesperson(true);
              }}
            >
              Crear Vendedor
            </Button>
          </Col>
        </Row>
        <Table
          style={{
            overflowX: "hidden", // Evita el desbordamiento
            width: "100%",
          }}
          loading={loading}
          locale="es"
          dataSource={allSalespersons}
          columns={columns}
          rowKey="id"
          scroll={{ x: 800, y: 500 }}
        />
      </Card>
      <NewSalesperson
        showModal={showNewSalesperson}
        setShowModal={setShowNewSalesperson}
        refetch={refetch}
        editingSalesperson={editingSalesperson}
        setEditingSalesperson={setEditingSalesperson}
      />
    </Container>
  );
};

export default Salesperson;
