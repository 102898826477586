import axios from "axios";
import { backend } from "config/environment";
import cookies from "react-cookies";

const client = axios.create({
  baseURL: backend.AUTH_URI,
  timeout: 100000,
  withCredentials: true,
});

const login = async (input) => {
  const { data } = await client.post("/auth/login", input);
  return data;
};

const registerClient = async (input) => {
  const { data } = await client.post("auth/registerClient", input);
  return data;
};

const registerUser = async (input) => {
  const { data } = await client.post("auth/registerUser", input);
  return data;
};

const checkPassword = async (input) => {
  const { data } = await client.get(
    `auth/checkPassword/${input.id}/${input.password}`
  );
  return data;
};

const updatePassword = async (input) => {
  const { data } = await client.put("/auth/updatePassword", input);
  return data;
};

const logout = async () => {
  const token = cookies.load("token"); // Cargar el token desde las cookies

  if (!token) {
    throw new Error("No token found for logout");
  }

  try {
    const response = await client.post(
      "auth/logout",
      {}, // Si necesitas enviar un body, puedes incluirlo aquí
      {
        headers: {
          Authorization: `Bearer ${token}`, // Incluir el token en la cabecera
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error during logout:", error);
    throw error; // Lanzar el error para manejarlo en el componente
  }
};

const removeToken = async (userId) => {
  const token = cookies.load("token"); // Cargar el token desde las cookies

  if (!token) {
    throw new Error("No token found for logout");
  }

  try {
    const response = await client.post(
      "auth/expire-token",
      {
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error during remove token:", error);
    throw error; // Lanzar el error para manejarlo en el componente
  }
};

export {
  login,
  logout,
  registerClient,
  registerUser,
  checkPassword,
  updatePassword,
  removeToken,
};
export default client;
