import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  Typography,
  Tag,
  Row,
  Tooltip,
  Button,
  Spin,
  Alert,
} from "antd";
import {
  StarFilled,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons"; // Iconos adicionales
import TableTitle from "./table-title";
import DetailDrawer from "./components/details";
import { Container, RowInfo } from "./elements";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "./requests";
import { formatCurrency } from "utils/functions";

const { Title, Text } = Typography;

// Diccionario de colores para categorías
const CATEGORY_COLORS = {
  EXCLUSIVOS: "blue",
  BIENESTAR: "green",
  "SMOKE SHOP": "volcano",
  ARTISTAS: "cyan",
  OTROS: "geekblue",
};

// Función para obtener el color de una categoría
const getCategoryColor = (category) =>
  CATEGORY_COLORS[category?.toUpperCase()] || "gray";

const Products = () => {
  const [kitOrIndiv, setKitOrIndiv] = useState("all");
  const [searchTitle, setSearchTitle] = useState("");
  const [searchSku, setSearchSku] = useState("");
  const [productCategory, setProductCategory] = useState(null);
  const [productSubcategory, setProductSubcategory] = useState(null);
  const [open, setOpen] = useState(false);
  const [productInfo, setProductInfo] = useState(undefined);
  const [params, setParams] = useState({ page: 1, pageSize: 10 });

  const { data, refetch, loading, error } = useQuery(GET_PRODUCTS, {
    variables: {
      filters: {
        isBundle: kitOrIndiv,
        title: searchTitle,
        sku: searchSku,
        productCategory,
        productSubcategory,
      },
      params,
    },
    onCompleted: (data) => {
      console.log("✅ Datos obtenidos:", data);
      if (!data?.products?.results?.length) {
        console.warn(
          "⚠️ No se encontraron productos con los filtros aplicados."
        );
      }
    },
    onError: (error) => console.error("❌ Error en GraphQL:", error),
  });

  useEffect(() => {
    console.log("📌 Estado actualizado:", {
      kitOrIndiv,
      searchTitle,
      searchSku,
      productCategory,
      productSubcategory,
      params,
    });
  }, [
    kitOrIndiv,
    searchTitle,
    searchSku,
    productCategory,
    productSubcategory,
    params,
  ]);

  const handleOpen = (row) => {
    setProductInfo(row);
    setOpen(true);
  };

  const columns = [
    {
      title: "newPhoto",
      align: "center",
      key: "newPhoto",
      render: (row) =>
        row.newPhoto ? (
          <img
            src={row.newPhoto}
            style={{ width: 100, height: 100, borderRadius: 50 }}
          />
        ) : (
          <Tag color="red">Sin Imagen</Tag>
        ),
    },
    {
      title: "Img",
      align: "center",
      key: "img",
      render: (row) =>
        row.img ? (
          <img
            src={row.img}
            style={{ width: 100, height: 100, borderRadius: 50 }}
          />
        ) : (
          <Tag color="red">Sin Imagen</Tag>
        ),
    },
    {
      title: "Producto",
      key: "title",
      render: (row) => (
        <RowInfo>
          <Title
            level={5}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            }}
          >
            {row.title}
          </Title>
          <p
            style={{
              maxHeight: "40px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {row.description || "Sin descripción"}
          </p>
          <p
            style={{
              maxHeight: "40px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {row.longDescription || "---"}
          </p>
        </RowInfo>
      ),
    },
    {
      title: "Ingredientes",
      key: "ingredients",
      render: (row) => {
        console.log("📌 Ingredientes recibidos:", row.ingredients); // ✅ Debug para verificar
        return (
          <RowInfo style={{ maxHeight: "50px", overflow: "hidden" }}>
            {Array.isArray(row.ingredients) && row.ingredients.length > 0 ? (
              row.ingredients.map((ingredient, index) => (
                <Tag
                  color="blue"
                  key={index}
                  style={{
                    marginBottom: "5px",
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {ingredient}
                </Tag>
              ))
            ) : (
              <Tag color="red">N/A</Tag> // Si no hay ingredientes, mostramos "N/A"
            )}
          </RowInfo>
        );
      },
    },

    {
      title: "Odoo ID",
      key: "odooId",
      render: (row) => <Title level={5}>{row.odooId || "N/A"}</Title>,
    },
    {
      title: "SKU",
      key: "sku",
      render: (row) => <Title level={5}>{row.sku || "N/A"}</Title>,
    },
    {
      title: "Precio",
      key: "price",
      render: (row) => {
        if (row.price?.amount) {
          return <Title level={5}>{formatCurrency(row.price)}</Title>;
        }
        return <Tag color="red">No disponible</Tag>;
      },
    },
    {
      title: "Categoría",
      key: "productCategory",
      render: (row) =>
        row.productCategory ? (
          <Tag color={getCategoryColor(row.productCategory)}>
            {row.productCategory}
          </Tag>
        ) : (
          <Tag color="red">N/A</Tag>
        ),
    },
    {
      title: "Subcategoría",
      key: "productSubcategory",
      render: (row) =>
        row.productSubcategory ? (
          <Tag color="purple">{row.productSubcategory}</Tag>
        ) : (
          <Tag color="red">N/A</Tag>
        ),
    },
    {
      title: "Recomendado",
      key: "recommended",
      align: "center",
      render: (row) =>
        row.recommended ? (
          <Tooltip title="Producto Recomendado">
            <StarFilled style={{ color: "gold", fontSize: "1.2rem" }} />
          </Tooltip>
        ) : (
          <Tag color="red">No</Tag>
        ),
    },
    {
      title: "Venta",
      key: "saleOk",
      align: "center",
      render: (row) =>
        row.saleOk ? (
          <Tag color="green">
            <CheckCircleOutlined /> Disponible
          </Tag>
        ) : (
          <Tag color="red">
            <CloseCircleOutlined /> No Disponible
          </Tag>
        ),
    },
    {
      title: "Tipo",
      key: "isBundle",
      align: "center",
      render: (row) =>
        row.isBundle ? (
          <Tag color="green">Kit</Tag>
        ) : (
          <Tag color="blue">Individual</Tag>
        ),
    },
    {
      title: "Acciones",
      key: "action",
      render: (row) => (
        <Row>
          <Tooltip placement="top" title="Información">
            <Button
              shape="circle"
              type="primary"
              icon={<InfoCircleOutlined style={{ color: "white" }} />}
              onClick={() => handleOpen(row)}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];

  return (
    <Container>
      <Card style={{ borderRadius: 5, width: "100%" }}>
        <TableTitle
          refetch={refetch}
          kitOrIndiv={kitOrIndiv}
          setKitOrIndiv={setKitOrIndiv}
          searchSku={searchSku}
          setSearchSku={setSearchSku}
          searchTitle={searchTitle}
          setSearchTitle={setSearchTitle}
          productCategory={productCategory}
          setProductCategory={setProductCategory}
          productSubcategory={productSubcategory}
          setProductSubcategory={setProductSubcategory}
        />

        {/* 🔴 Mostrar error si GraphQL falla */}
        {error && (
          <Alert
            message="Error al obtener productos"
            description="Ocurrió un error al cargar los datos. Verifique la consola para más detalles."
            type="error"
            showIcon
            style={{ marginBottom: 20 }}
          />
        )}

        <Table
          dataSource={data?.products?.results || []}
          columns={columns}
          rowKey="id"
          loading={loading ? { indicator: <Spin />, spinning: true } : false}
          locale={{
            emptyText: "No se encontraron productos",
          }}
          pagination={{
            current: Number(params.page),
            pageSize: Number(params.pageSize),
            total: data?.products?.info?.count || 0,
            showTotal: (total) => <Text disabled>{`${total} resultados`}</Text>,
            onChange: (page, pageSize) => setParams({ page, pageSize }),
          }}
          scroll={{ x: 800, y: 500 }}
        />
      </Card>

      {productInfo && (
        <DetailDrawer
          open={open}
          setOpen={setOpen}
          product={productInfo}
          setProduct={setProductInfo}
          refetch={refetch}
        />
      )}
    </Container>
  );
};

export default Products;
