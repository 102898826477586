import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Image, Button, Menu } from "antd";
import {
  FacebookOutlined,
  UserOutlined,
  LogoutOutlined,
  BarChartOutlined,
  ToolOutlined,
  CheckOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
  AppstoreOutlined,
  VideoCameraOutlined,
  GiftOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { useUser } from "providers/user/index";

import logo from "./logo.png";

const Sidebar = ({ history }) => {
  const { user, logout } = useUser();
  const userType = user?.userType;
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  // 📌 Definir permisos por tipo de usuario
  const permissions = {
    admin: [
      "dashboard",
      "sync-operations",
      "orders",
      "abandoned",
      "franchise",
      "products",
      "promotions",
      "social-networks",
      "reels",
      "users",
      "memberships",
      "push-notifications",
    ],
    employee: ["memberships"],
    marketing: ["social-networks", "reels"],
    salesperson: ["orders", "abandoned"],
  };

  const hasAccess = (key) => {
    return (
      userType !== "client" &&
      (userType === "admin" || permissions[userType]?.includes(key))
    );
  };

  const currentPath = history.location.pathname.toLowerCase();

  const menuItems = [
    hasAccess("dashboard") && {
      key: "/dashboard",
      icon: <BarChartOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    hasAccess("sync-operations") && {
      key: "/sync-operations",
      icon: <ToolOutlined />,
      label: <Link to="/sync-operations">Operaciones</Link>,
    },
    hasAccess("orders") && {
      key: "/orders",
      icon: <CheckOutlined />,
      label: <Link to="/orders">Órdenes Completadas</Link>,
    },
    hasAccess("abandoned") && {
      key: "/abandoned",
      icon: <ShoppingCartOutlined />,
      label: <Link to="/abandoned">Carritos Abandonados</Link>,
    },
    hasAccess("franchise") && {
      key: "/franchise",
      icon: <ShopOutlined />,
      label: <Link to="/franchise">Franquicias</Link>,
    },
    hasAccess("products") && {
      key: "products",
      icon: <AppstoreOutlined />,
      label: "Productos",
      children: [
        {
          key: "/subcategories",
          label: <Link to="/subcategories">Subcategorías</Link>,
        },
        { key: "/products", label: <Link to="/products">Productos</Link> },
        { key: "/landing", label: <Link to="/landing">Landing</Link> },
        {
          key: "/collaborations",
          label: <Link to="/collaborations">Artistas</Link>,
        },
      ],
    },
    hasAccess("promotions") && {
      key: "promotions",
      icon: <GiftOutlined />,
      label: "Promociones",
      children: [
        { key: "/banners", label: <Link to="/banners">Banners</Link> },
        { key: "/bundles", label: <Link to="/bundles">Bundles</Link> },
        { key: "/discounts", label: <Link to="/discounts">Descuentos</Link> },
        {
          key: "/notifications",
          label: <Link to="/notifications">Notificaciones</Link>,
        },
      ],
    },
    hasAccess("social-networks") && {
      key: "/social-networks",
      icon: <FacebookOutlined />,
      label: <Link to="/social-networks">Redes Sociales</Link>,
    },
    hasAccess("reels") && {
      key: "/reels",
      icon: <VideoCameraOutlined />,
      label: <Link to="/reels">Reels</Link>,
    },
    hasAccess("users") && {
      key: "users",
      icon: <UserOutlined />,
      label: "Usuarios",
      children: [
        { key: "/clients", label: <Link to="/clients">Clientes</Link> },
        { key: "/admins", label: <Link to="/admins">Admins</Link> },
        { key: "/employee", label: <Link to="/employee">Empleados</Link> },
        { key: "/marketing", label: <Link to="/marketing">Marketing</Link> },
        {
          key: "/salesperson",
          label: <Link to="/salesperson">Ventas</Link>,
        },
      ],
    },
    hasAccess("memberships") && {
      key: "/memberships",
      icon: <ShopOutlined />,
      label: <Link to="/memberships">Memberships</Link>,
    },
    hasAccess("push-notifications") && {
      key: "/push-notifications",
      icon: <NotificationOutlined />,
      label: <Link to="/push-notifications">Notificaciones Push</Link>,
    },
  ].filter(Boolean);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        minWidth: "250px", // 🔥 Establecer un ancho mínimo
      }}
    >
      {/* Logo */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 10,
        }}
      >
        <Image height={70} src={logo} alt="Paradise" preview={false} />
      </div>

      {/* Menú (scrollable) */}
      <div style={{ flex: 1, overflowY: "auto", paddingTop: 10 }}>
        <Menu
          style={{ marginTop: 10, background: "none" }}
          mode="inline"
          selectedKeys={[currentPath]} // 🔥 Esto activa la selección
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          items={menuItems}
        />
      </div>

      {/* Botón de logout */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 10,
        }}
      >
        <Button
          className="btnPrimary"
          style={{ width: "100%" }}
          onClick={logout}
        >
          <LogoutOutlined /> Salir
        </Button>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
