import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Tooltip,
  Button,
  Table,
  Card,
  Image,
  Badge,
  Tag,
  //Tag,
} from "antd";
import { Container, RowInfo } from "./elements";
import TableTitle from "./table-title";
import { useQuery } from "@apollo/client";
import { InfoCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import { GET_FRANCHISES } from "./requests";
import DetailDrawer from "./components/details/index";
import ScheduleDrawer from "./components/schedule/index";
//import moment from "moment";
import PhonesEditModal from "./components/phones-edit-modal/index";

const { Title, Text } = Typography;

const Franchise = () => {
  const [franchiseSearch, setFranchiseSearch] = useState("");
  const { data, refetch, loading } = useQuery(GET_FRANCHISES, {
    variables: {
      params: { pageSize: 300, page: 1 },
      franchise: franchiseSearch,
    },
  });
  const [showDetailDrawer, setShowDetailDrawer] = useState(false);
  const [showScheduleDrawer, setShowSchedultDrawer] = useState(false);
  const [current, setCurrent] = useState(null);
  const [currentFranchisesIds, setCurrentFranchisesIds] = useState([]);
  const [phoneSearch, setPhoneSearch] = useState(false);
  const [showPhoneEditModal, setShowPhoneEditModal] = useState(false);

  const [locationSearch, setLocationSearch] = useState(false);
  useEffect(() => {
    if (data?.franchises?.results) {
      setCurrentFranchisesIds(data.franchises.results.map((row) => row.odooId));
    }
  }, [data]);

  /*const dayTranslation = {
    lunes: "monday",
    martes: "tuesday",
    miércoles: "wednesday",
    jueves: "thursday",
    viernes: "friday",
    sábado: "saturday",
    domingo: "sunday",
  };*/

  /*   const renderStatus = (franchise) => {
    const isValidPhone = franchise?.phone && franchise?.phone !== "false";

    if (isValidPhone && franchise?.schedule) {
      const todayDate = moment().format("YYYY-MM-DD");
      const todayTime = moment();
      const dayOfWeek = moment().format("dddd");

      const hoursRegex = /^\d{1,2}:\d{2}-\d{1,2}:\d{2}$/;
      const daySchedule = franchise.schedule[dayTranslation[dayOfWeek]];

      let startTime, endTime;

      if (hoursRegex.test(daySchedule)) {
        startTime = moment(daySchedule.split("-")[0], "H:mm");
        endTime = moment(daySchedule.split("-")[1], "H:mm");
      }

      const index = franchise?.events?.findIndex(
        (event) => event.date === todayDate
      );

      if (index >= 0) {
        const content = franchise?.events[index].content;
        if (content !== "closed" && content !== "holiday") {
          startTime = moment(content.split("-")[0], "H:mm");
          endTime = moment(content.split("-")[1], "H:mm");
        }
      }

      if (
        startTime &&
        endTime &&
        startTime.isBefore(todayTime) &&
        todayTime.isBefore(endTime)
      ) {
        return <Tag color="green">Activo</Tag>;
      }
    }

    return <Tag color="red">Inactivo</Tag>;
  }; */

  const filterItems = () => {
    let filteredObjects = data?.franchises?.results || [];

    console.log(phoneSearch);
    console.log(locationSearch);
    if (phoneSearch == "saved") {
      filteredObjects = filteredObjects.filter((franchise) => franchise.phone);
    } else if (phoneSearch == "notSaved") {
      filteredObjects = filteredObjects.filter(
        (franchise) => !franchise.phone || franchise.phone === "false"
      );
    }

    if (locationSearch == "saved") {
      filteredObjects = filteredObjects.filter(
        (franchise) => franchise.address.location.coordinates
      );
    } else if (locationSearch == "notSaved") {
      filteredObjects = filteredObjects.filter(
        (franchise) => !franchise.address.location.coordinates
      );
    }

    return filteredObjects;
  };

  const filters = [
    {
      type: "select",
      label: "Localización: ",
      width: 150,
      defaultValue: false,
      onChange: (value) => setLocationSearch(value),
      options: [
        { label: "Cualquiera", value: false },
        { label: "Existente", value: "saved" },
        { label: "No existente", value: "notSaved" },
      ],
    },
    {
      type: "select",
      label: "Teléfono: ",
      width: 150,
      defaultValue: false,
      onChange: (value) => setPhoneSearch(value),
      options: [
        { label: "Cualquiera", value: false },
        { label: "Existente", value: "saved" },
        { label: "No existente", value: "notSaved" },
      ],
    },
  ];

  const columns = [
    {
      align: "center",
      key: "cover",
      width: "5%",
      render: (row) => {
        return <Image src={row.img} style={{ width: 100 }} />;
      },
    },
    {
      title: "Nombre",
      width: "12%",
      align: "left",
      key: "name",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.name}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Tipo de franquicia",
      width: "15%",
      align: "center",
      key: "isPublicCedis",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>
              {row.isPublicCedis ? (
                <>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    CEDIS Público
                  </span>
                  <br />
                  <Tooltip title={`Latitud: ${row.lat}, Longitud: ${row.lng}`}>
                    <Tag
                      color="blue"
                      style={{
                        cursor: "pointer",
                        padding: "5px 10px",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      Ubicación
                    </Tag>
                  </Tooltip>
                </>
              ) : (
                <span style={{ color: "gray" }}>Privada</span>
              )}
            </Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Odoo ID",
      width: "5%",
      align: "left",
      key: "odooId",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.odooId}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Warehouse ID",
      width: "5%",
      align: "left",
      key: "warehouseId",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.warehouseId}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Último miembro ID",
      width: "8%",
      align: "left",
      key: "lastMemberId",
      render: (row) => {
        return row.lastMemberId !== 0 ? (
          <RowInfo>
            <Title level={5}>{row.lastMemberId}</Title>
          </RowInfo>
        ) : null;
      },
    },
    {
      title: "Estatus",
      width: "8%",
      align: "center",
      key: "status",
      render: (row) => {
        return (
          <RowInfo>
            <Badge
              color={row.status === "active" ? "green" : "red"}
              text={
                row.status === "active" ? (
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    Activo
                  </span>
                ) : (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    Inactivo
                  </span>
                )
              }
            />
          </RowInfo>
        );
      },
    },
    {
      title: "Dirección",
      width: "18%",
      align: "left",
      key: "address",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>
              {!row?.address.fullName || row?.address.fullName === "false"
                ? "No existe dirección"
                : row?.address.fullName}
            </Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Teléfono",
      width: "10%",
      align: "center",
      key: "phone",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row?.phone || "No existe teléfono"}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Órdenes",
      width: "8%",
      align: "center",
      key: "orders",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row?.totalOrders ?? 0}</Title>
          </RowInfo>
        );
      },
    },

    {
      title: () => (
        <Text
          level={4}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          Acciones
        </Text>
      ),
      width: "10%",
      align: "center",
      key: "orders",
      render: (row) => {
        return (
          <Row>
            <Tooltip placement="top" title="Más información">
              <Button
                shape="circle"
                type="primary"
                size="large"
                icon={<InfoCircleOutlined style={{ color: "white" }} />}
                style={{ margin: "auto", display: "block" }}
                onClick={() => {
                  setShowDetailDrawer(true);
                  setCurrent(row);
                }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Horario y calendario">
              <Button
                shape="circle"
                type="primary"
                size="large"
                icon={<CalendarOutlined style={{ color: "white" }} />}
                style={{
                  backgroundColor: "green",
                  margin: "auto",
                  display: "block",
                }}
                onClick={() => {
                  setShowSchedultDrawer(true);
                  setCurrent(row);
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <Container>
      {showPhoneEditModal ? (
        <PhonesEditModal
          open={showPhoneEditModal}
          setOpen={setShowPhoneEditModal}
          data={data?.["franchises"]?.["results"]}
          refetch={refetch}
        />
      ) : undefined}
      <Card
        style={{
          borderRadius: 5,
          width: "100%",
        }}
      >
        <Table
          style={{
            overflowX: "hidden", // Esto asegura que no desborde el contenedor
            width: "100%",
          }}
          loading={loading}
          locale="es"
          title={() => (
            <TableTitle
              filters={filters}
              currentFranchisesIds={currentFranchisesIds}
              refetch={refetch}
              setFranchiseSearch={setFranchiseSearch}
              setShowPhoneEditModal={setShowPhoneEditModal}
            />
          )}
          dataSource={filterItems()}
          columns={columns}
          rowKey="id"
          scroll={{ x: 800, y: 500 }}
        />
      </Card>

      <DetailDrawer
        open={showDetailDrawer}
        setOpen={setShowDetailDrawer}
        setCurrent={setCurrent}
        currentFranchise={current}
        refetch={refetch}
      />

      <ScheduleDrawer
        open={showScheduleDrawer}
        setOpen={setShowSchedultDrawer}
        setCurrent={setCurrent}
        currentFranchise={current}
        refetch={refetch}
      />
    </Container>
  );
};

export default Franchise;
