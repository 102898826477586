import { gql } from "@apollo/client";

const GET_USERS_CLIENTS = gql`
  query users($filters: UserInputFilter) {
    users(filters: $filters) {
      results {
        id
        firstName
        lastName
        email
        phone
        mobile
        img
        activeToken
        faceImage
        client {
          locations {
            id
            odooId
            name
            address {
              fullName
              country
              state
              city
              street
              postcode
              colony
            }
          }
        }
      }
    }
  }
`;

export { GET_USERS_CLIENTS };
