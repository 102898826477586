import React from "react";
import PropTypes from "prop-types";
import { Typography, Row, Col, Select, Input, DatePicker } from "antd";
import { TitleContainer } from "./elements";

const { Text } = Typography;
const { RangePicker } = DatePicker;
const TableTitle = ({ filters, clients, setClientFilter }) => {
  return (
    <TitleContainer>
      <Row
        gutter={[16, 16]} // Espaciado entre columnas
        style={{ width: "100%" }}
        justify="start"
        align="middle"
      >
        {/* Título */}
        <Col xs={24} sm={24} md={24} lg={24}>
          <Text style={{ fontSize: 20, fontWeight: "800" }}>Órdenes</Text>
        </Col>

        {/* Filtros dinámicos */}
        {filters.map((filter, index) => (
          <Col
            key={index}
            xs={24} // Una fila por filtro en pantallas pequeñas
            sm={12} // Dos filtros por fila en pantallas pequeñas
            md={8} // Tres filtros por fila en pantallas medianas
            lg={6} // Cuatro filtros por fila en pantallas grandes
          >
            <Text level={5} style={{ fontWeight: "800" }}>
              {filter.label}
            </Text>
            {filter.type === "date" && (
              <RangePicker
                size="middle"
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                onChange={filter.onChange}
              />
            )}
            {filter.type === "search" && (
              <Input
                style={{ width: "100%" }}
                placeholder={filter.placeholder}
                allowClear
                onChange={filter.onChange}
              />
            )}
            {filter.type === "select" && (
              <Select
                defaultValue={filter.defaultValue}
                style={{ width: "100%" }}
                placeholder={filter.placeholder}
                onChange={filter.onChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={filter?.options ?? []}
              />
            )}
          </Col>
        ))}

        {/* Buscar por Cliente */}
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text level={5} style={{ fontSize: "0.8rem", fontWeight: "800" }}>
            Buscar por Cliente
          </Text>
          <Select
            showSearch
            allowClear
            placeholder="Escribe para buscar"
            optionFilterProp="children"
            onChange={(value) => setClientFilter(value)}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input.toLowerCase())
            }
          >
            {clients.map((client) => (
              <Select.Option key={client.id} value={client.id}>
                {`${client.user.firstName} ${client.user.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </TitleContainer>
  );
};

TableTitle.defaultProps = {
  search: "",
};

TableTitle.propTypes = {
  search: PropTypes.string,
};

export default TableTitle;
