import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_URI + "/firebase";

// Obtener todas las notificaciones con paginación y filtros
export const getNotifications = async (params = {}) => {
  try {
    const response = await axios.get(`${BASE_URL}/logs`, { params });
    console.log("notif: ", response);
    return response.data;
  } catch (error) {
    console.error("Error al obtener notificaciones:", error);
    throw error;
  }
};

// Enviar notificación a un usuario específico
export const sendNotificationToUser = async (notificationData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/send-to-user`,
      notificationData
    );
    return response.data;
  } catch (error) {
    console.error("Error al enviar notificación a usuario:", error);
    throw error;
  }
};

// Enviar notificación a usuarios filtrados por criterios
export const sendNotificationToFilteredUsers = async (notificationData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/send-to-filtered-users`,
      notificationData
    );
    return response.data;
  } catch (error) {
    console.error("Error al enviar notificación a usuarios filtrados:", error);
    throw error;
  }
};

// Enviar notificación a un topic
export const sendNotificationToTopic = async (notificationData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/send-to-topic`,
      notificationData
    );
    return response.data;
  } catch (error) {
    console.error("Error al enviar notificación a un topic:", error);
    throw error;
  }
};
