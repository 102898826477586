import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Card,
  Input,
  Select,
  message,
  Row,
  Col,
  Drawer,
  Form,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  getNotifications,
  sendNotificationToUser,
  sendNotificationToFilteredUsers,
  sendNotificationToTopic,
} from "./requests";
import { useLazyQuery } from "@apollo/client";
import { GET_USERS_CLIENTS } from "./graphql";

const { Option } = Select;

const NotificationManager = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState(null);
  const [fetchUsers, { data: usersData, loading: usersLoading }] = useLazyQuery(
    GET_USERS_CLIENTS,
    {
      variables: { filters: { userType: "client" } },
      fetchPolicy: "network-only",
    }
  );

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const data = await getNotifications({
        title: searchTitle,
        type: notificationType,
      });
      setNotifications(data.logs);
    } catch (error) {
      message.error("Error al obtener notificaciones");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNotifications();
  }, [searchTitle, notificationType]);

  const handleSendNotification = async (values) => {
    try {
      let response;
      if (values.type === "user") {
        response = await sendNotificationToUser(values);
      } else if (values.type === "group") {
        response = await sendNotificationToFilteredUsers(values);
      } else {
        response = await sendNotificationToTopic(values);
      }
      console.log(response);
      message.success("Notificación enviada correctamente");
      setOpenDrawer(false);
      form.resetFields();
      fetchNotifications();
    } catch (error) {
      message.error("Error al enviar la notificación");
    }
  };

  const columns = [
    { title: "ID", dataIndex: "_id", key: "id" },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (_, log) =>
        log.topic ? "Topic" : log.userId ? "Usuario" : "Grupo",
    },
    { title: "Título", dataIndex: "title", key: "title" },
    { title: "Cuerpo", dataIndex: "body", key: "body" },
    { title: "Response", dataIndex: "response", key: "response" },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span style={{ color: status === "sent" ? "green" : "red" }}>
          {status}
        </span>
      ),
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleString(),
    },
  ];

  return (
    <Card>
      <h1 className="text-2xl font-bold">Gestión de Notificaciones</h1>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col>
          <Input
            placeholder="Buscar por título"
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            allowClear
          />
        </Col>
        <Col>
          <Select
            placeholder="Filtrar por tipo"
            value={notificationType}
            onChange={setNotificationType}
            allowClear
          >
            <Option value="user">Usuario</Option>
            <Option value="group">Grupo</Option>
            <Option value="topic">Topic</Option>
          </Select>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setOpenDrawer(true)}
          >
            Enviar Notificación
          </Button>
        </Col>
      </Row>
      <Table
        loading={loading}
        dataSource={notifications}
        columns={columns}
        rowKey="_id"
      />

      {/* Drawer para enviar notificación */}
      <Drawer
        title="Nueva Notificación"
        placement="right"
        onClose={() => setOpenDrawer(false)}
        visible={openDrawer}
      >
        <Form layout="vertical" form={form} onFinish={handleSendNotification}>
          <Form.Item
            name="type"
            label="Tipo de Notificación"
            rules={[{ required: true, message: "Selecciona un tipo" }]}
          >
            <Select
              onChange={(value) => {
                setSelectedType(value);
                form.setFieldsValue({ type: value });
                if (value === "user") fetchUsers();
              }}
            >
              <Option value="user">A un Usuario</Option>
              <Option value="group">A un Grupo</Option>
              <Option value="topic">A un Topic</Option>
            </Select>
          </Form.Item>
          {selectedType === "user" && (
            <Form.Item name="userId" label="Selecciona un Usuario">
              <Select loading={usersLoading}>
                {usersData?.users?.results.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName} - {user.email}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {selectedType === "group" && (
            <>
              <Form.Item name="minAge" label="Edad Mínima" initialValue={18}>
                <Select>
                  {[...Array(73)].map((_, i) => (
                    <Option key={i + 18} value={i + 18}>
                      {i + 18}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="maxAge" label="Edad Máxima" initialValue={90}>
                <Select>
                  {[...Array(73)].map((_, i) => (
                    <Option key={i + 18} value={i + 18}>
                      {i + 18}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="city" label="Ciudad">
                <Input placeholder="Ingrese la ciudad" />
              </Form.Item>
            </>
          )}
          {selectedType === "topic" && (
            <Form.Item
              name="topic"
              label="Nombre del Topic"
              initialValue="news"
            >
              <Select>
                <Option value="news">News</Option>
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="title"
            label="Título"
            rules={[{ required: true, message: "Ingresa un título" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="body"
            label="Mensaje"
            rules={[{ required: true, message: "Ingresa un mensaje" }]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form>
      </Drawer>
    </Card>
  );
};

export default NotificationManager;
