import React, { useState } from "react";
import {
  Drawer,
  Typography,
  Divider,
  Row,
  Col,
  Table,
  Button,
  Image,
  List,
  Tag,
  Tooltip,
  message,
  Select,
} from "antd";
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import {
  orderStatus,
  mapPaymentMethod,
  mapPaymentStatus,
} from "utils/constants";
import TitleSubtitle from "utils/components/title-subtitle";
import { formatCurrency } from "utils/functions";
import { Money } from "utils/types";
import { UPDATE_ORDER } from "./requests";
import { useMutation } from "@apollo/client";

const { Option } = Select;

const DetailDrawer = ({
  open,
  setOpen,
  currentOrder,
  setCurrentOrder,
  refetch,
}) => {
  const [editing, setEditing] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [updateOrder] = useMutation(UPDATE_ORDER);

  if (!currentOrder) return null; // Previene crash si no hay datos

  const changeStatus = async () => {
    if (!newStatus) {
      message.warning("Selecciona un estatus antes de guardar.");
      return;
    }

    try {
      await updateOrder({
        variables: {
          id: currentOrder?.id,
          order: { status: newStatus },
        },
      });
      await refetch();
      message.success("Orden Actualizada!");
      onClose();
    } catch (err) {
      console.error("Error al actualizar la orden:", err);
      message.error("Error al actualizar la orden.");
    }
  };

  const onClose = () => {
    setOpen(false);
    setCurrentOrder(undefined);
    setEditing(false);
    setNewStatus(null);
  };

  const productsColumns = [
    {
      title: "Imagen",
      dataIndex: ["product", "img"],
      key: "image",
      render: (img) => (
        <Image
          src={img || "https://via.placeholder.com/100"}
          alt="product"
          width={100}
          style={{ height: "auto" }}
        />
      ),
    },
    {
      title: "Título",
      dataIndex: ["product", "title"],
      key: "title",
      render: (title) => (
        <Typography.Text strong>{title || "N/A"}</Typography.Text>
      ),
    },
    {
      title: "SKU",
      dataIndex: ["product", "sku"],
      key: "sku",
      render: (sku) => <Typography.Text>{sku || "N/A"}</Typography.Text>,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity) => <Typography.Text>{quantity || 0}</Typography.Text>,
    },
  ];
  console.log(currentOrder);
  // Filtrar elementos nulos o sin propiedad 'product'
  const filteredLineItems =
    currentOrder?.lineItems?.filter((item) => item && item.product) || [];

  return (
    <Drawer
      title="Detalles de la orden"
      placement="right"
      width={"40%"}
      onClose={onClose}
      open={open}
      extra={
        <>
          {!editing ? (
            <Tooltip title="Editar">
              <Button
                type="primary"
                size="large"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => setEditing(true)}
              />
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Guardar">
                <Button
                  type="primary"
                  size="large"
                  shape="circle"
                  icon={<SaveOutlined />}
                  onClick={changeStatus}
                />
              </Tooltip>
              <Tooltip title="Cancelar">
                <Button
                  style={{ marginLeft: 10 }}
                  type="default"
                  size="large"
                  shape="circle"
                  icon={<CloseOutlined />}
                  onClick={() => setEditing(false)}
                />
              </Tooltip>
            </>
          )}
        </>
      }
    >
      <TitleSubtitle
        title="Referencia"
        subtitle={currentOrder?.name || "N/A"}
      />
      <TitleSubtitle
        title="Cliente"
        subtitle={`${currentOrder?.client?.user?.firstName || "N/A"} ${
          currentOrder?.client?.user?.lastName || ""
        }`}
      />
      {editing ? (
        <div style={{ marginBottom: 10 }}>
          <Typography.Text style={{ color: "grey" }}>Estatus</Typography.Text>
          <Select
            defaultValue={orderStatus[currentOrder?.status]?.name || "N/A"}
            onChange={(value) => setNewStatus(value)}
            style={{ width: 200 }}
          >
            {Object.values(orderStatus).map((status) => (
              <Option
                key={status.value}
                value={status.value}
                style={{ color: status.color }}
              >
                {status.name}
              </Option>
            ))}
          </Select>
        </div>
      ) : (
        <TitleSubtitle
          title="Estatus"
          subtitle={orderStatus[currentOrder?.status]?.name || "N/A"}
        />
      )}

      <TitleSubtitle
        title="Destino"
        subtitle={currentOrder?.location?.address?.fullName || "N/A"}
      />
      <Divider />
      <Typography.Title level={2}>Pago</Typography.Title>
      <Row justify="space-between" align="middle">
        <Col>Subtotal</Col>
        <Col>
          {currentOrder?.payment?.subTotal?.amount
            ? formatCurrency(currentOrder.payment.subTotal)
            : "N/A"}
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>Impuestos</Col>
        <Col>{formatCurrency(currentOrder?.payment?.tax)}</Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>Propina</Col>
        <Col>{formatCurrency(currentOrder?.payment?.tip)}</Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>Envío</Col>
        <Col>{formatCurrency(currentOrder?.delivery?.deliveryPrice)}</Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>Total</Col>
        <Col>
          {formatCurrency(new Money(currentOrder?.payment?.total?.amount || 0))}
        </Col>
      </Row>
      <Divider />
      <Typography.Text strong style={{ fontSize: "20px" }}>
        Intentos de pago
      </Typography.Text>
      <List
        itemLayout="horizontal"
        dataSource={currentOrder?.paymentProcessor || []}
        renderItem={(item, idx) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <>
                  {idx + 1}
                  {") "}
                  <img
                    width={200}
                    src={mapPaymentMethod[item?.method]?.image ?? ""}
                  />
                </>
              }
              title={
                <Row style={{ marginTop: "5vh" }}>
                  <Tag color={mapPaymentStatus[item?.status]?.color ?? "red"}>
                    {mapPaymentStatus[item?.status]?.name || "N/A"}
                  </Tag>
                </Row>
              }
              description={
                <>
                  <Row>
                    <Typography.Text strong style={{ fontSize: "15px" }}>
                      ID de pago:{" "}
                      {item?.method === "Open Pay"
                        ? item?.openPayId
                        : item?.clipId || "N/A"}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text strong style={{ fontSize: "15px" }}>
                      <a href={item?.url} target="_blank" rel="noreferrer">
                        URL de Pago
                      </a>
                    </Typography.Text>
                  </Row>
                </>
              }
            />
          </List.Item>
        )}
      />
      <Divider />
      <Typography.Text strong style={{ fontSize: "20px" }}>
        Productos ({filteredLineItems.length || 0})
      </Typography.Text>
      {filteredLineItems.length > 0 ? (
        <Table
          dataSource={filteredLineItems}
          columns={productsColumns}
          rowKey={(item) => item?.product?.id || "fallback-key"} // Validación para evitar errores
          pagination={false}
          bordered
        />
      ) : (
        <Typography.Text>No hay productos en esta orden.</Typography.Text>
      )}
    </Drawer>
  );
};

export default DetailDrawer;
