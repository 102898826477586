import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { message } from "antd";
import { useUser } from "providers/user";

// 📌 Importamos las rutas
import Dashboard from "./routes/dashboard";
import Orders from "./routes/orders";
import AbandonedOrders from "./routes/abandoned-orders";
import SocialNetworks from "./routes/social-networks";
import Reels from "./routes/reels";
import Franchise from "./routes/franchise";
import SyncOperation from "./routes/sync-operations";
import Products from "./routes/products";
import Landing from "./routes/landing";
import Collaborations from "./routes/collaborations";
import ProductCategories from "./routes/product-categories";
import Subcategories from "./routes/subcategories";
import Banners from "./routes/banners";
import Bundles from "./routes/bundles";
import Discounts from "./routes/discounts";
import Notifications from "./routes/notifications";
import Clients from "./routes/clients";
import Admins from "./routes/admins";
import Employee from "./routes/employee";
import Marketing from "./routes/marketing";
import Memberships from "./routes/memberships";
import PushNotifications from "./routes/push-notifications";
import Salesperson from "./routes/salesperson/index";
// 📌 Definir permisos por rol
const permissions = {
  admin: [
    "/dashboard",
    "/orders",
    "/abandoned",
    "/products",
    "/landing",
    "/collaborations",
    "/categories",
    "/franchise",
    "/banners",
    "/bundles",
    "/discounts",
    "/notifications",
    "/clients",
    "/admins",
    "/employee",
    "/salesperson",
    "/marketing",
    "/sync-operations",
    "/social-networks",
    "/reels",
    "/memberships",
    "/subcategories",
    "/push-notifications",
  ],
  employee: ["/memberships"],
  marketing: ["/social-networks", "/reels"],
  client: [],
  salesperson: ["/orders", "/abandoned"],
};

const Dash = () => {
  const { user, logout } = useUser(); // 🔥 Ahora obtenemos `logout`
  const history = useHistory();
  const userType = user?.userType || "client"; // Si no hay usuario, se asume cliente
  console.log(userType);
  // 📌 Si el usuario es cliente, cerramos sesión y redirigimos
  useEffect(() => {
    if (userType === "client") {
      message.error("No tienes permisos para acceder.");
      logout(); // ❌ Llamamos `logout` para borrar sesión completamente
      history.replace("/login"); // 🔄 Redirigimos al login
    }
  }, [userType, history, logout]);

  return (
    <Switch>
      {permissions[userType].map((route) => (
        <Route key={route} path={route} component={getComponent(route)} />
      ))}

      {/* 📌 Si intentan acceder a una página no permitida, redirigir */}
      <Redirect to={permissions[userType][0] || "/dashboard"} />
    </Switch>
  );
};

// 🔥 Función para obtener el componente según la ruta
const getComponent = (route) => {
  switch (route) {
    case "/dashboard":
      return Dashboard;
    case "/orders":
      return Orders;
    case "/abandoned":
      return AbandonedOrders;
    case "/products":
      return Products;
    case "/landing":
      return Landing;
    case "/collaborations":
      return Collaborations;
    case "/categories":
      return ProductCategories;
    case "/franchise":
      return Franchise;
    case "/banners":
      return Banners;
    case "/bundles":
      return Bundles;
    case "/discounts":
      return Discounts;
    case "/notifications":
      return Notifications;
    case "/clients":
      return Clients;
    case "/admins":
      return Admins;
    case "/employee":
      return Employee;
    case "/salesperson":
      return Salesperson;
    case "/marketing":
      return Marketing;
    case "/sync-operations":
      return SyncOperation;
    case "/social-networks":
      return SocialNetworks;
    case "/reels":
      return Reels;
    case "/memberships":
      return Memberships;
    case "/subcategories":
      return Subcategories;
    case "/push-notifications":
      return PushNotifications;
    default:
      return Dashboard;
  }
};

export default Dash;
