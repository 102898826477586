import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  Typography,
  Row,
  Col,
  Input,
  Button,
  Popconfirm,
  message,
  InputNumber,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import NewMembership from "./components/new-membership";
import { getMemberships, deleteMembership } from "./requests"; // Importamos los métodos de requests.js
import * as XLSX from "xlsx";

const { Title, Text } = Typography;

const Memberships = () => {
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchCURP, setSearchCURP] = useState("");
  const [searchName, setSearchName] = useState("");
  const [showNewMembership, setShowNewMembership] = useState(false);
  const [editingMembership, setEditingMembership] = useState(null);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(1);

  const fetchMemberships = async () => {
    setLoading(true);

    // Validación de CURP (entre 8 y 18 caracteres)
    if (searchCURP && (searchCURP.length < 8 || searchCURP.length > 18)) {
      message.error("La CURP debe tener entre 8 y 18 caracteres.");
      setLoading(false);
      return;
    }

    // Validación de nombre (mínimo 3 caracteres)
    if (searchName && searchName.length < 3) {
      message.error("El nombre debe tener al menos 3 caracteres.");
      setLoading(false);
      return;
    }

    try {
      const data = await getMemberships({
        curp: searchCURP,
        name: searchName,
        limit: 10000,
        offset: 0,
      });
      console.log("Datos recibidos:", data);
      setMemberships(data.data.members);
    } catch (error) {
      message.error("Error al obtener las membresías");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchMemberships();
  }, [searchCURP, searchName]);

  const handleDelete = async (id) => {
    try {
      await deleteMembership(id);
      message.success("Membresía eliminada correctamente");
      fetchMemberships();
    } catch (error) {
      message.error("Error al eliminar la membresía");
    }
  };

  const handleEdit = (membership) => {
    setEditingMembership(membership);
    setShowNewMembership(true);
  };
  const exportToExcel = async () => {
    if (endPage - startPage >= 10) {
      message.error("El rango de páginas no puede ser mayor a 10.");
      return;
    }

    let allData = [];
    for (let page = startPage; page <= endPage; page++) {
      try {
        const data = await getMemberships({
          curp: searchCURP,
          name: searchName,
          limit: 10000,
          offset: (page - 1) * 10000,
        });
        allData = allData.concat(data.data.members);
      } catch (error) {
        message.error(`Error obteniendo datos de la página ${page}`);
        return;
      }
    }

    const ws = XLSX.utils.json_to_sheet(allData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Membresías");
    XLSX.writeFile(wb, "Membresías.xlsx");
  };
  const columns = [
    {
      title: "Member ID",
      align: "center",
      key: "memberId",
      dataIndex: "memberId",
    },
    {
      title: "Nombre",
      align: "left",
      key: "user",
      render: (member) => (
        <Row>
          <Title level={5}>{member.firstName}</Title>
        </Row>
      ),
    },
    {
      title: "Apellido Paterno",
      align: "left",
      key: "user",
      render: (member) => (
        <Row>
          <Title level={5}>{member.lastName}</Title>
        </Row>
      ),
    },
    {
      title: "Apellido Materno",
      align: "left",
      key: "user",
      render: (member) => (
        <Row>
          <Title level={5}>{member.middleName}</Title>
        </Row>
      ),
    },
    {
      title: "CURP",
      align: "left",
      key: "curp",
      dataIndex: "curp",
    },
    {
      title: "Edad",
      align: "center",
      key: "age",
      dataIndex: "age",
    },
    {
      title: "País",
      align: "left",
      key: "country",
      dataIndex: "country",
    },
    {
      title: "Estado",
      align: "left",
      key: "state",
      dataIndex: "state",
    },
    {
      title: "Municipio",
      align: "left",
      key: "city",
      dataIndex: "city",
    },
    {
      title: "Teléfono",
      align: "left",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Correo Electrónico",
      align: "left",
      key: "email",
      dataIndex: "email",
    },
    /*     {
      title: "ID Franquicia",
      align: "left",
      key: "franchiseId",
      dataIndex: "franchiseId",
    }, */
    {
      title: "Franquicia",
      align: "left",
      key: "franchiseName",
      dataIndex: "franchiseName",
    },
    {
      title: "Acciones",
      align: "center",
      key: "actions",
      render: (row) => (
        <Row>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(row)}
            style={{ marginRight: 8 }}
          />
          <Popconfirm
            title="¿Seguro que deseas eliminar esta membresía?"
            onConfirm={() => handleDelete(row._id)}
            okText="Sí"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Row>
      ),
    },
  ];

  return (
    <Card style={{ borderRadius: 5 }}>
      <Text style={{ fontSize: 20, fontWeight: "800" }}>Memberships</Text>
      <Row justify="space-between">
        <Col span={16}>
          <Row gutter={[16, 16]}>
            <Col>
              <Text strong>Filtrar por CURP</Text>
              <Input
                placeholder="Buscar por CURP"
                value={searchCURP}
                onChange={(e) => setSearchCURP(e.target.value)}
                allowClear
              />
            </Col>
            <Col>
              <Text strong>Filtrar por Nombre</Text>
              <Input
                placeholder="Buscar por Nombre"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                allowClear
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditingMembership(null);
              setShowNewMembership(true);
            }}
          >
            Crear Membresía
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col>
          <Text strong>Página Inicial</Text>
          <InputNumber min={1} value={startPage} onChange={setStartPage} />
        </Col>
        <Col>
          <Text strong>Página Final</Text>
          <InputNumber min={1} value={endPage} onChange={setEndPage} />
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={exportToExcel}
          >
            Exportar a Excel
          </Button>
        </Col>
      </Row>
      <Table
        style={{ overflowX: "hidden", width: "100%", marginTop: "20px" }}
        loading={loading}
        locale="es"
        dataSource={memberships}
        columns={columns}
        rowKey="_id"
        scroll={{ x: 800, y: 500 }}
      />
      <NewMembership
        showModal={showNewMembership}
        setShowModal={setShowNewMembership}
        refetch={fetchMemberships}
        editingMembership={editingMembership}
        setEditingMembership={setEditingMembership}
      />
    </Card>
  );
};

export default Memberships;
