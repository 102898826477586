import React, { useEffect } from "react";
import { Drawer, Form, Input, Button, Typography, Select, message } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { CREATE_USER, UPDATE_USER, GET_FRANCHISES } from "./requests";

const { Text } = Typography;
const { Option } = Select;

const NewEmployee = ({
  showModal,
  setShowModal,
  refetch,
  editingEmployee,
  setEditingEmployee,
}) => {
  const [form] = Form.useForm();
  const { data, loading } = useQuery(GET_FRANCHISES, {
    variables: { params: { page: 1, pageSize: 200 } }, // 🔹 Traemos hasta 50 franquicias
  });

  useEffect(() => {
    if (!showModal) {
      form.resetFields();
      setEditingEmployee(null);
    }
  }, [showModal]);

  useEffect(() => {
    if (editingEmployee && showModal) {
      form.setFieldsValue({
        ...editingEmployee,
        password: "",
        franchiseId: editingEmployee.franchiseId || undefined,
      });
    }
  }, [editingEmployee, showModal]);

  const [createUserMutation] = useMutation(CREATE_USER);
  const [updateUserMutation] = useMutation(UPDATE_USER);

  const onClose = () => {
    setShowModal(false);
    form.resetFields();
    setEditingEmployee(null);
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    try {
      let response;

      if (editingEmployee) {
        console.log("✏️ Editando empleado...: ", editingEmployee);
        const updatedValues = { ...values };
        if (!values.password) delete updatedValues.password;

        const hasChanges = Object.keys(updatedValues).some(
          (key) => updatedValues[key] !== editingEmployee[key]
        );

        if (!hasChanges) {
          message.warning("No hay cambios para actualizar.");
          return;
        }

        response = await updateUserMutation({
          variables: { id: editingEmployee.id, user: updatedValues },
        });

        if (!response?.data?.updateUser?.success) {
          throw new Error(
            response?.data?.updateUser?.message || "Error al actualizar."
          );
        }

        message.success("Empleado actualizado correctamente.");
      } else {
        console.log("🆕 Creando nuevo empleado...");

        const payload = {
          variables: {
            user: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              password: values.password,
              userType: "employee",
              franchiseId: values.franchiseId,
              img: values.img || "",
              phone: values.phone || "",
              mobile: values.mobile || "",
            },
          },
        };

        response = await createUserMutation(payload);

        if (!response?.data?.createUser?.success) {
          throw new Error(
            response?.data?.createUser?.message || "Error al crear usuario."
          );
        }

        message.success("Empleado creado correctamente.");
      }

      await refetch();
      onClose();
    } catch (error) {
      console.error("❌ GraphQL Error:", error);

      let errorMessage = "Ha ocurrido un error al guardar el empleado.";

      if (error.graphQLErrors?.length) {
        errorMessage = error.graphQLErrors[0].message || errorMessage;
      } else if (error.networkError) {
        errorMessage = "Error de conexión con el servidor.";
      } else {
        errorMessage = error.message || errorMessage;
      }

      message.error(errorMessage);
    }
  };

  return (
    <Drawer
      title={editingEmployee ? "Editar Empleado" : "Nuevo Empleado"}
      visible={showModal}
      onClose={onClose}
      width={"60vw"}
    >
      <Form
        form={form}
        style={{ paddingLeft: 40, paddingRight: 40 }}
        onFinish={onSubmit}
      >
        <Text strong>
          Nombre<Text style={{ color: "red" }}> *</Text>
        </Text>
        <Form.Item
          style={{ marginTop: 10 }}
          name="firstName"
          rules={[{ required: true, message: "Por favor ingrese el nombre " }]}
        >
          <Input size="large" placeholder="Ingrese el nombre" />
        </Form.Item>

        <Text strong>
          Apellido<Text style={{ color: "red" }}> *</Text>
        </Text>
        <Form.Item
          style={{ marginTop: 10 }}
          name="lastName"
          rules={[
            { required: true, message: "Por favor ingrese el apellido " },
          ]}
        >
          <Input size="large" placeholder="Ingrese el apellido" />
        </Form.Item>

        <Text strong>
          Correo electrónico<Text style={{ color: "red" }}> *</Text>
        </Text>
        <Form.Item
          style={{ marginTop: 10 }}
          name="email"
          rules={[{ required: true, message: "Por favor ingrese el correo" }]}
        >
          <Input type="email" size="large" placeholder="Ingrese el correo" />
        </Form.Item>

        <Text strong>
          Contraseña{" "}
          {editingEmployee ? "(Opcional, solo si desea cambiarla)" : "*"}
        </Text>
        <Form.Item
          style={{ marginTop: 10 }}
          name="password"
          rules={
            editingEmployee
              ? []
              : [{ required: true, message: "Por favor ingrese la contraseña" }]
          }
        >
          <Input.Password size="large" placeholder="Ingrese la contraseña" />
        </Form.Item>

        {/* Selección de franquicia */}
        <Text strong>
          Franquicia<Text style={{ color: "red" }}> *</Text>
        </Text>
        <Form.Item
          style={{ marginTop: 10 }}
          name="franchiseId"
          rules={[
            { required: true, message: "Por favor seleccione franquicia" },
          ]}
        >
          <Select
            size="large"
            loading={loading}
            placeholder="Seleccione una franquicia"
            showSearch // 🔹 Habilita la búsqueda
            optionFilterProp="children" // 🔹 Filtra por el texto de las opciones
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            } // 🔹 Filtra según el texto ingresado por el usuario
          >
            {data?.franchises?.results?.map((franchise) => (
              <Option key={franchise.id} value={franchise.id}>
                {franchise.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Text strong>Teléfono</Text>
        <Form.Item style={{ marginTop: 10 }} name="phone">
          <Input size="large" placeholder="Ingrese el número de teléfono" />
        </Form.Item>

        <Text strong>Móvil</Text>
        <Form.Item style={{ marginTop: 10 }} name="mobile">
          <Input size="large" placeholder="Ingrese el número de móvil" />
        </Form.Item>

        <Form.Item>
          <Button size="large" block type="primary" htmlType="submit">
            {editingEmployee ? "Actualizar Empleado" : "Crear Empleado"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default NewEmployee;
