import { gql } from "@apollo/client";

const GET_PRODUCTS = gql`
  query products($params: QueryParams, $filters: ProductFilters) {
    products(params: $params, filters: $filters) {
      results {
        id
        newPhoto
        longDescription
        ingredients
        rating
        totalReviews
        odooId
        resizedImg
        base64Img
        odooId
        title
        img
        variant
        variants {
          title
          sku
          img
          description
        }
        description
        price {
          amount
          exponent
          currency
        }
        category {
          name
          value
        }
        tags {
          name
          value
        }
        sku
        productCategory
        productSubcategory
        bundle {
          id
          odooId
          title
          description
          img
          products {
            product {
              title
              sku
              img
              description
            }
            quantity
          }
          active
        }
        isBundle
        saleOk
        recommended
        relatedProducts {
          id
          odooId
          title
          description
          img
          price {
            amount
            exponent
            currency
          }
          category {
            name
            value
          }
          tags {
            name
            value
          }
          sku
        }
        traits {
          title
          description
        }
      }
      info {
        count
        prev
        next
        pages
      }
    }
  }
`;

export { GET_PRODUCTS };
