import { gql } from "@apollo/client";

const GET_FRANCHISES = gql`
  query franchises($params: QueryParams, $franchise: String) {
    franchises(params: $params, franchise: $franchise) {
      results {
        id
        odooId
        name
        img
        lastMemberId
        status
        mainWarehouse
        warehouseId
        isPublicCedis
        lat
        lng
        address {
          fullName
          country
          city
          state
          street
          postcode
          colony
          location {
            type
            coordinates
          }
        }
        phone
        totalOrders
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        events {
          date
          content
        }
      }
    }
  }
`;

const UPDATE_FRANCHISE = gql`
  mutation updateFranchise($id: ID!, $franchise: FranchiseUpdateInput!) {
    updateFranchise(id: $id, franchise: $franchise) {
      id
    }
  }
`;

const UPDATE_MAIN_WAREHOUSE = gql`
  mutation updateMainWarehouse($id: ID!, $mainWarehouse: Boolean!) {
    updateMainWarehouse(id: $id, mainWarehouse: $mainWarehouse) {
      id
    }
  }
`;

export { GET_FRANCHISES, UPDATE_FRANCHISE, UPDATE_MAIN_WAREHOUSE };
